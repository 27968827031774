<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Login</h1>
                <p class="text-muted">Accede a tu cuenta</p>
                <CInput
                  id="user"
                  ref="user"
                  placeholder="Usuario"
                  autocomplete="username email"
                  @keypress.enter="focusPasswd"
                >
                  <template #prepend-content>
                    <CIcon name="cil-user" />
                  </template>
                </CInput>
                <CInput
                  id="password"
                  ref="passwd"
                  placeholder="Contraseña"
                  :type="togglePassword"
                  autocomplete="curent-password"
                  @keypress.enter="comprobarLogin"
                >
                  <template #prepend-content>
                    <CIcon
                      :name="icon"
                      title="Clicar para mostrar u ocultar contraseña"
                      @click.native="toggleShowPasswd"
                    />
                  </template>
                </CInput>
                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton color="primary" class="px-4" @click="comprobarLogin()">Login</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="9" class="p-4">
          <CAlert color="danger" dismissible :show.sync="showErrorAlert" close-button>
            <strong>AVISO</strong>
 la contraseña o el usuario insertado son
            incorrectos
            <CProgress :max="10" :value="showErrorAlert" height="3px" color="danger" animated />
          </CAlert>
          <CAlert color="warning" dismissible :show.sync="showServerAlert" close-button>
            <strong>AVISO</strong>
 ha habido un error. Por favor, inténtelo de
            nuevo en unos minutos
            <CProgress :max="10" :value="showServerAlert" height="3px" color="danger" animated />
          </CAlert>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import "core-js/stable";
import "regenerator-runtime/runtime";
import axios from "axios";
import config from "@/config";

export default {
  name: "Login",
  data() {
    return {
      showErrorAlert: 0,
      showServerAlert: 0,
      icon: "cil-lock-locked",
      togglePassword: "password"
    };
  },
  created() {
    localStorage.removeItem("token");
  },
  mounted() {
    this.$refs.user.$el.getElementsByTagName("input")[0].focus();
  },
  methods: {
    comprobarLogin() {
      let username = document.getElementById("user").value;
      let password = document.getElementById("password").value;

      axios
        .post(config.BACK_IP + "/user/login", { username,password })
        .then(
          response => {
            localStorage.token = response.data.token;
            window.location.href = "/";
          },
          error => {
            if (error.response == undefined) {
              this.mostrarFallo(2);
              return;
            }
            if (
              error.response.status == 403 ||
              error.response.status == 400 ||
              error.response.status == 404
            ) {
              this.mostrarFallo(1);
            } else {
              this.mostrarFallo(2);
            }
          }
        );
    },
    mostrarFallo(numero) {
      if (numero == 1) {
        this.showErrorAlert = 10;
      } else {
        this.showServerAlert = 10;
      }
    },
    focusPasswd() {
      this.$refs.passwd.$el.getElementsByTagName("input")[0].focus();
    },
    toggleShowPasswd() {
      if (this.icon === "cil-lock-locked") {
        this.icon = "cil-lock-unlocked";
        this.togglePassword = "text";
      } else {
        this.icon = "cil-lock-locked";
        this.togglePassword = "password";
      }
    }
  }
};
</script>
